<template>
  <div
    v-bind:style="[
      !$globalData.isMb
        ? { width: '100%', height: '100%', position: 'relative' }
        : {},
    ]"
  >
    <div v-if="$globalData.isMb">
      <div class="project-slide-show-header-wrapper">
        <div
          style="
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            width: 45px;
            height: 45px;
          "
        >
          <img
            style="width: 100%; height: 100%; cursor: pointer"
            src="../assets/close.png"
            v-on:click="closeButtonClick"
          />
        </div>
        {{ projectName
        }}<span v-if="projectSize != null"
          ><br />實用面積 {{ projectSize.toLocaleString("en-US") }} ft</span
        >
      </div>
      <div class="project-slide-show-wrapper">
        <div
          class="project-slide-show"
          v-touch:swipe.left="goNext"
          v-touch:swipe.right="goPrevious"
        >
          <img
            src="../assets/icon_btn_360.png"
            class="project-360-image-button"
            v-on:click="open360Photo"
            v-if="isShow360Button"
          />

          <div
            class="slide-item-wrapper"
            v-bind:class="{ ts: enabledTs }"
            v-bind:style="tl"
          >
            <div
              class="slide-show-item"
              v-for="(image, index) in images"
              :key="index"
              v-bind:style="image.tl"
            >
              <img
                v-if="
                  image.width != null &&
                  image.height != null &&
                  image.width >= image.height
                "
                style="width: 100%; height: auto"
                :src="image.url"
                v-bind:style="getImagePositionYOffset(index)"
              />
              <img
                v-if="
                  image.width != null &&
                  image.height != null &&
                  image.width < image.height
                "
                style="width: auto; height: 100%"
                :src="image.url"
              />
            </div>
          </div>
        </div>
        <div
          class="dot-wrapper"
          v-if="isShowDotWrapper"
          v-bind:style="dotWrapperLeft"
          style="padding: 0 30px; box-sizing: border-box; left: 0; width: 100%"
        >
          <div
            class="dot"
            v-bind:class="{
              'dot-active': currentPosition == index + 1,
              'dot-inactive': currentPosition != index + 1,
            }"
            v-for="(image, index) in images"
            :key="index"
            v-on:click="go(index + 1)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!$globalData.isMb"
      style="
        background-color: #837f64;
        width: 100%;
        height: 100%;
        position: relative;
      "
    >
      <div
        style="
          width: 35%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          letter-spacing: 1px;
          color: #fff;
          padding: 150px 0 0 15px;
        "
      >
        {{ projectName
        }}<span v-if="projectSize != null"
          ><br />實用面積 {{ projectSize.toLocaleString("en-US") }} ft</span
        >
      </div>

      <div
        style="
          width: 65%;
          height: 100%;
          position: absolute;
          background-color: #fff;
          top: 0;
          right: 0;
        "
      ></div>

      <div
        style="
          position: absolute;
          top: 0;
          right: 0;
          padding: 10px;
          width: 45px;
          height: 45px;
        "
      >
        <img
          style="width: 100%; height: 100%; cursor: pointer"
          src="../assets/close.png"
          v-on:click="closeButtonClick"
        />
      </div>

      <div class="project-slide-show-wrapper">
        <div
          class="project-slide-show"
          v-touch:swipe.left="goNext"
          v-touch:swipe.right="goPrevious"
        >
          <img
            src="../assets/project_previous.jpg"
            class="project-image-previous"
            v-on:click="goPrevious"
          />
          <img
            src="../assets/project_next.jpg"
            class="project-image-next"
            v-on:click="goNext"
          />
          <img
            src="../assets/icon_btn_360.png"
            class="project-360-image-button"
            v-on:click="open360Photo"
            v-if="isShow360Button"
          />

          <div
            class="slide-item-wrapper"
            v-bind:class="{ ts: enabledTs }"
            v-bind:style="tl"
          >
            <div
              class="slide-show-item"
              v-for="(image, index) in images"
              :key="index"
              v-bind:style="image.tl"
            >
              <img
                v-if="
                  image.width != null &&
                  image.height != null &&
                  image.width >= image.height
                "
                style="width: 100%; height: auto"
                :src="image.url"
                v-bind:style="getImagePositionYOffset(index)"
              />
              <img
                v-if="
                  image.width != null &&
                  image.height != null &&
                  image.width < image.height
                "
                style="width: auto; height: 100%"
                :src="image.url"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="dot-wrapper"
        v-if="isShowDotWrapper"
        v-bind:style="dotWrapperLeft"
      >
        <div
          class="dot"
          v-bind:class="{
            'dot-active': currentPosition == index + 1,
            'dot-inactive': currentPosition != index + 1,
          }"
          v-for="(image, index) in images"
          :key="index"
          v-on:click="go(index + 1)"
          v-bind:style="[
            currentPosition == index + 1
              ? { 'background-color': 'rgba(0, 0, 0, 0.5)' }
              : { 'background-color': 'rgba(0, 0, 0, 0.2)' },
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { SEARCH_PROJECT_DETAIL_API_PATH } from "../constants";

export default {
  name: "ProjectSlideShow",
  data() {
    return {
      rootStyle: "width: 100%; height: 100%; position: relative;",
      isMb: null,
      currentPosition: null,
      lastPosition: null,
      images: [
        //{ url: "http://deepthought.com.hk/demo_img/mb_img_01.jpg", mbUrl: "http://deepthought.com.hk/demo_img/mb_img_01.jpg", tl: "", width: null, height: null },
        //{ url: "https://wallpaperboat.com/wp-content/uploads/2020/04/dark-city-wallpaper-1920x1080-1.jpg", mbUrl: "http://deepthought.com.hk/demo_img/mb_img_02.jpg", tl: "", width: null, height: null },
        //{ url: "https://wallpaperboat.com/wp-content/uploads/2020/04/dark-city-wallpaper-free-18.jpg", mbUrl: "http://deepthought.com.hk/demo_img/mb_img_03.jpg", tl: "", width: null, height: null }
      ],
      enabledTs: true,
      tl: "transform: translate(0, 0);",
      isLockAction: false,
      intervalObj: null,
      intervalMs: 5000,
      isShowDotWrapper: false,
      dotWrapperLeft: "",
    };
  },
  props: ["project-id", "project-name", "project-size", "selected-partition"],
  created: function () {
    let that = this;
    this.currentPosition = 1;
    this.resetAutoPlay();

    let postData = {
      projectId: this.projectId,
      selectedPartition: this.selectedPartition,
    };

    axios
      .post(SEARCH_PROJECT_DETAIL_API_PATH, postData)
      .then(function (res) {
        that.images = res.data;

        for (let i = 0; i < that.images.length; i++) {
          let im = new Image();

          im.onload = () => {
            that.$set(that.images[i], "width", im.width);
            that.$set(that.images[i], "height", im.height);
          };
          im.src = "/" + that.images[i].url;
        }

        that.recalculateDotWrapperWidth();
      })
      .catch(function (ex) {
        that.images = [];
      });
  },
  methods: {
    closeButtonClick: function () {
      this.$emit("closeClick");
    },
    getImagePositionYOffset: function (index) {
      let slideH;

      if (this.$globalData.windowWidth < 576) slideH = 300;
      else if (this.$globalData.windowWidth < 768) slideH = 400;
      else if (this.$globalData.windowWidth < 880) slideH = 400;
      else if (this.$globalData.windowWidth < 992) slideH = 430;
      else if (this.$globalData.windowWidth < 1096) slideH = 480;
      else if (this.$globalData.windowWidth < 1200) slideH = 500;
      else if (this.$globalData.windowWidth < 1400) slideH = 580;
      else if (this.$globalData.windowWidth < 1600) slideH = 620;
      else slideH = 700;

      let w = this.images[index].width;
      let h = this.images[index].height;
      let actualOffset = 0;

      if (this.$globalData.isMb)
        actualOffset =
          (slideH - Math.floor((this.$globalData.windowWidth / w) * h)) / 2;
      else
        actualOffset =
          (slideH - Math.floor((this.$globalData.windowWidth / w) * 0.7 * h)) /
          2;

      return "transform: translateY(" + actualOffset.toString() + "px);";
    },
    go: function (seq) {
      if (this.images == null || this.images.length < 2) return;

      if (this.isLockAction) return;

      if (seq === this.currentPosition) return;

      this.isLockAction = true;
      this.enabledTs = true;
      this.resetAutoPlay();
      this.lastPosition = this.currentPosition;
      this.currentPosition = seq;

      let offset = (this.currentPosition - 1) * 100;
      this.tl = "transform: translate(-" + offset.toString() + "%, 0);";
      this.isLockAction = false;
    },
    goPrevious: async function () {
      if (this.images == null || this.images.length < 2) return;

      if (this.isLockAction) return;

      this.isLockAction = true;
      this.enabledTs = true;
      this.resetAutoPlay();
      this.lastPosition = this.currentPosition;
      this.currentPosition =
        this.currentPosition === 1
          ? this.images.length
          : this.currentPosition - 1;

      if (this.lastPosition === 1) {
        let tsOffset = this.images.length * 100;
        this.$set(
          this.images[this.images.length - 1],
          "tl",
          "transform: translate(-" + tsOffset.toString() + "%, 0);"
        );
        this.tl = "transform: translate(100%, 0);";
        await this.sleep(600);

        this.enabledTs = false;
        await this.$nextTick();

        this.$set(
          this.images[this.images.length - 1],
          "tl",
          "transform: translate(0, 0);"
        );
        this.tl =
          "transform: translate(-" + (tsOffset - 100).toString() + "%, 0);";
        await this.$nextTick();
      } else {
        let offset = (this.currentPosition - 1) * 100;
        this.tl = "transform: translate(-" + offset.toString() + "%, 0);";
      }

      this.isLockAction = false;
    },
    goNext: async function () {
      if (this.images == null || this.images.length < 2) return;

      if (this.isLockAction) return;

      this.isLockAction = true;
      this.enabledTs = true;
      this.resetAutoPlay();
      this.lastPosition = this.currentPosition;
      this.currentPosition =
        this.currentPosition === this.images.length
          ? 1
          : this.currentPosition + 1;

      if (this.lastPosition === this.images.length) {
        let tsOffset = this.images.length * 100;
        this.$set(
          this.images[0],
          "tl",
          "transform: translate(" + tsOffset.toString() + "%, 0);"
        );
        this.tl = "transform: translate(-" + tsOffset.toString() + "%, 0);";
        await this.sleep(600);

        this.enabledTs = false;
        await this.$nextTick();

        this.$set(this.images[0], "tl", "transform: translate(0, 0);");
        this.tl = "transform: translate(0, 0);";
        await this.$nextTick();
      } else {
        let offset = (this.currentPosition - 1) * 100;
        this.tl = "transform: translate(-" + offset.toString() + "%, 0);";
      }

      this.isLockAction = false;
    },
    open360Photo: function () {
      let pid = this.projectId;

      if (pid == 620) window.open("/360photo/620.html");
      else if (pid == 622) window.open("/360photo/622.html");
      else if (pid == 624) window.open("/360photo/624.html");
      else if (pid == 626) window.open("/360photo/626.html");
      else if (pid == 628) window.open("/360photo/628.html");
    },
    resetAutoPlay: function () {
      let that = this;
      if (this.intervalObj != null) clearInterval(this.intervalObj);

      this.intervalObj = setInterval(() => {
        that.goNext();
      }, this.intervalMs);
    },
    recalculateDotWrapperWidth: function () {
      if (this.images != null && this.images.length > 1) {
        if (this.$globalData.isMb) {
          //this.dotWrapperLeft = 'left: calc(50% - ' + ((((this.images.length * 23) - 15) / 2) + 30) + 'px);';
          this.dotWrapperLeft = "";
        } else this.dotWrapperLeft = "width: 70%; right: 0;";

        this.isShowDotWrapper = true;
      }
    },
    sleep: function (ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
  },
  computed: {
    // a computed getter
    isShow360Button: function () {
      let pid = this.projectId;

      if (pid == 620 || pid == 622 || pid == 624 || pid == 626 || pid == 628)
        return true;
      else return false;
    },
  },
};
//that.$nextTick(function () {

//});
</script>