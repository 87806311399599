var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:([
    !_vm.$globalData.isMb
      ? { width: '100%', height: '100%', position: 'relative' }
      : {} ])},[(_vm.$globalData.isMb)?_c('div',[_c('div',{staticClass:"project-slide-show-header-wrapper"},[_c('div',{staticStyle:{"position":"absolute","top":"0","right":"0","padding":"10px","width":"45px","height":"45px"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","cursor":"pointer"},attrs:{"src":require("../assets/close.png")},on:{"click":_vm.closeButtonClick}})]),_vm._v(" "+_vm._s(_vm.projectName)),(_vm.projectSize != null)?_c('span',[_c('br'),_vm._v("實用面積 "+_vm._s(_vm.projectSize.toLocaleString("en-US"))+" ft")]):_vm._e()]),_c('div',{staticClass:"project-slide-show-wrapper"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.goNext),expression:"goNext",arg:"swipe",modifiers:{"left":true}},{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.goPrevious),expression:"goPrevious",arg:"swipe",modifiers:{"right":true}}],staticClass:"project-slide-show"},[(_vm.isShow360Button)?_c('img',{staticClass:"project-360-image-button",attrs:{"src":require("../assets/icon_btn_360.png")},on:{"click":_vm.open360Photo}}):_vm._e(),_c('div',{staticClass:"slide-item-wrapper",class:{ ts: _vm.enabledTs },style:(_vm.tl)},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"slide-show-item",style:(image.tl)},[(
                image.width != null &&
                image.height != null &&
                image.width >= image.height
              )?_c('img',{staticStyle:{"width":"100%","height":"auto"},style:(_vm.getImagePositionYOffset(index)),attrs:{"src":image.url}}):_vm._e(),(
                image.width != null &&
                image.height != null &&
                image.width < image.height
              )?_c('img',{staticStyle:{"width":"auto","height":"100%"},attrs:{"src":image.url}}):_vm._e()])}),0)]),(_vm.isShowDotWrapper)?_c('div',{staticClass:"dot-wrapper",staticStyle:{"padding":"0 30px","box-sizing":"border-box","left":"0","width":"100%"},style:(_vm.dotWrapperLeft)},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"dot",class:{
            'dot-active': _vm.currentPosition == index + 1,
            'dot-inactive': _vm.currentPosition != index + 1,
          },on:{"click":function($event){return _vm.go(index + 1)}}})}),0):_vm._e()])]):_vm._e(),(!_vm.$globalData.isMb)?_c('div',{staticStyle:{"background-color":"#837f64","width":"100%","height":"100%","position":"relative"}},[_c('div',{staticStyle:{"width":"35%","height":"100%","position":"absolute","left":"0","top":"0","letter-spacing":"1px","color":"#fff","padding":"150px 0 0 15px"}},[_vm._v(" "+_vm._s(_vm.projectName)),(_vm.projectSize != null)?_c('span',[_c('br'),_vm._v("實用面積 "+_vm._s(_vm.projectSize.toLocaleString("en-US"))+" ft")]):_vm._e()]),_c('div',{staticStyle:{"width":"65%","height":"100%","position":"absolute","background-color":"#fff","top":"0","right":"0"}}),_c('div',{staticStyle:{"position":"absolute","top":"0","right":"0","padding":"10px","width":"45px","height":"45px"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","cursor":"pointer"},attrs:{"src":require("../assets/close.png")},on:{"click":_vm.closeButtonClick}})]),_c('div',{staticClass:"project-slide-show-wrapper"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.goNext),expression:"goNext",arg:"swipe",modifiers:{"left":true}},{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.goPrevious),expression:"goPrevious",arg:"swipe",modifiers:{"right":true}}],staticClass:"project-slide-show"},[_c('img',{staticClass:"project-image-previous",attrs:{"src":require("../assets/project_previous.jpg")},on:{"click":_vm.goPrevious}}),_c('img',{staticClass:"project-image-next",attrs:{"src":require("../assets/project_next.jpg")},on:{"click":_vm.goNext}}),(_vm.isShow360Button)?_c('img',{staticClass:"project-360-image-button",attrs:{"src":require("../assets/icon_btn_360.png")},on:{"click":_vm.open360Photo}}):_vm._e(),_c('div',{staticClass:"slide-item-wrapper",class:{ ts: _vm.enabledTs },style:(_vm.tl)},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"slide-show-item",style:(image.tl)},[(
                image.width != null &&
                image.height != null &&
                image.width >= image.height
              )?_c('img',{staticStyle:{"width":"100%","height":"auto"},style:(_vm.getImagePositionYOffset(index)),attrs:{"src":image.url}}):_vm._e(),(
                image.width != null &&
                image.height != null &&
                image.width < image.height
              )?_c('img',{staticStyle:{"width":"auto","height":"100%"},attrs:{"src":image.url}}):_vm._e()])}),0)])]),(_vm.isShowDotWrapper)?_c('div',{staticClass:"dot-wrapper",style:(_vm.dotWrapperLeft)},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"dot",class:{
          'dot-active': _vm.currentPosition == index + 1,
          'dot-inactive': _vm.currentPosition != index + 1,
        },style:([
          _vm.currentPosition == index + 1
            ? { 'background-color': 'rgba(0, 0, 0, 0.5)' }
            : { 'background-color': 'rgba(0, 0, 0, 0.2)' } ]),on:{"click":function($event){return _vm.go(index + 1)}}})}),0):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }