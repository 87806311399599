<template>
  <div class="project-page">
    <div v-if="$globalData.isMb">
      <div
        v-bind:style="heightPx"
        class="mb-search-panel"
        v-if="isShowMbSearchPanel"
      >
        <div style="padding: 5px 15px 0 15px">
          <div style="position: relative">
            搜尋項目<span
              style="position: absolute; right: 0; cursor: pointer"
              v-on:click="resetData"
              >重置</span
            >
          </div>
        </div>

        <div class="mb-menu-hr" style="margin-top: 20px" />

        <div style="padding: 10px 15px 0 15px">
          <div style="position: relative" v-on:click="toggleStyle">
            風格
            <div class="colp-expn-img-wrapper">
              <img
                v-if="mbSearchPanelExpandStyle"
                src="../assets/collapse.png"
                style="height: 0.8em; width: auto"
              />
              <img
                v-if="!mbSearchPanelExpandStyle"
                src="../assets/expand.png"
                style="height: 0.8em; width: auto"
              />
            </div>
          </div>
        </div>

        <div class="mb-menu-hr" />

        <div
          class="mb-menu-items"
          v-bind:class="{ 'mb-menu-items-expand': mbSearchPanelExpandStyle }"
        >
          <div style="padding: 15px 0 0 15px">
            <div v-for="(y, index) in styleDataSource" v-bind:key="index">
              <label class="check-box-container mb-menu-checkbox">
                {{ y.name }}
                <input
                  type="checkbox"
                  v-bind:value="y.value"
                  v-model="selectedStyle"
                />
                <span class="checkmark"></span>
              </label>
              <br />
            </div>
          </div>
          <div class="mb-menu-hr" />
        </div>

        <div style="padding: 10px 15px 0 15px">
          <div style="position: relative" v-on:click="togglePartition">
            間格
            <div class="colp-expn-img-wrapper">
              <img
                v-if="mbSearchPanelExpandPartition"
                src="../assets/collapse.png"
                style="height: 0.8em; width: auto"
              />
              <img
                v-if="!mbSearchPanelExpandPartition"
                src="../assets/expand.png"
                style="height: 0.8em; width: auto"
              />
            </div>
          </div>
        </div>

        <div class="mb-menu-hr" />

        <div
          class="mb-menu-items"
          v-bind:class="{
            'mb-menu-items-expand': mbSearchPanelExpandPartition,
          }"
        >
          <div style="padding: 15px 0 0 15px">
            <div v-for="(y, index) in partitionDataSource" v-bind:key="index">
              <label class="check-box-container mb-menu-checkbox">
                {{ y.name }}
                <input
                  type="checkbox"
                  v-bind:value="y.value"
                  v-model="selectedPartition"
                />
                <span class="checkmark"></span>
              </label>
              <br />
            </div>
          </div>
          <div class="mb-menu-hr" />
        </div>

        <div style="padding: 10px 15px 0 15px">
          <div style="position: relative" v-on:click="toggleArea">
            面積
            <div class="colp-expn-img-wrapper">
              <img
                v-if="mbSearchPanelExpandArea"
                src="../assets/collapse.png"
                style="height: 0.8em; width: auto"
              />
              <img
                v-if="!mbSearchPanelExpandArea"
                src="../assets/expand.png"
                style="height: 0.8em; width: auto"
              />
            </div>
          </div>
        </div>

        <div class="mb-menu-hr" />

        <div
          class="mb-menu-items"
          v-bind:class="{ 'mb-menu-items-expand': mbSearchPanelExpandArea }"
        >
          <div style="padding: 15px 0 0 15px">
            <div v-for="(y, index) in areaDataSource" v-bind:key="index">
              <label class="check-box-container mb-menu-checkbox">
                {{ y.name }}
                <input
                  type="checkbox"
                  v-bind:value="y.value"
                  v-model="selectedArea"
                />
                <span class="checkmark"></span>
              </label>
              <br />
            </div>
          </div>
          <div class="mb-menu-hr" />
        </div>

        <div style="padding: 10px 15px 0 15px">
          <div style="position: relative" v-on:click="toggleType">
            類型
            <div class="colp-expn-img-wrapper">
              <img
                v-if="mbSearchPanelExpandType"
                src="../assets/collapse.png"
                style="height: 0.8em; width: auto"
              />
              <img
                v-if="!mbSearchPanelExpandType"
                src="../assets/expand.png"
                style="height: 0.8em; width: auto"
              />
            </div>
          </div>
        </div>

        <div class="mb-menu-hr" />

        <div
          class="mb-menu-items"
          v-bind:class="{ 'mb-menu-items-expand': mbSearchPanelExpandType }"
        >
          <div style="padding: 15px 0 0 15px">
            <div v-for="(y, index) in typeDataSource" v-bind:key="index">
              <label class="check-box-container mb-menu-checkbox">
                {{ y.name }}
                <input
                  type="checkbox"
                  v-bind:value="y.value"
                  v-model="selectedType"
                />
                <span class="checkmark"></span>
              </label>
              <br />
            </div>
          </div>
          <div class="mb-menu-hr" />
        </div>

        <div style="padding: 10px 15px 0 15px">
          <div style="position: relative" v-on:click="toggleProject">
            樓盤
            <div class="colp-expn-img-wrapper">
              <img
                v-if="mbSearchPanelExpandProject"
                src="../assets/collapse.png"
                style="height: 0.8em; width: auto"
              />
              <img
                v-if="!mbSearchPanelExpandProject"
                src="../assets/expand.png"
                style="height: 0.8em; width: auto"
              />
            </div>
          </div>
        </div>

        <div class="mb-menu-hr" />

        <div
          class="mb-menu-items"
          v-bind:class="{ 'mb-menu-items-expand': mbSearchPanelExpandProject }"
        >
          <div style="padding: 15px 0 0 15px">
            <div v-for="(y, index) in projectDataSource" v-bind:key="index">
              <label class="check-box-container mb-menu-checkbox">
                {{ y.name }}
                <input
                  type="checkbox"
                  v-bind:value="y.value"
                  v-model="selectedProject"
                />
                <span class="checkmark"></span>
              </label>
              <br />
            </div>
          </div>
          <div class="mb-menu-hr" />
        </div>

        <div class="custom-btn" v-on:click="search">確認</div>

        <div style="height: 70px">&nbsp;</div>
      </div>

      <div class="mb-about-section-1" v-if="!isShowMbSearchPanel">
        <div class="overlay">
          <div class="content">為客人打造心目中的</div>
          <div class="title">安樂窩</div>
          <div class="custom-btn" v-on:click="showMbSearchPanel">
            搜尋項目<img
              style="height: 20px; width: auto; transform: translate(8px, -1px)"
              src="../assets/search_go.png"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!$globalData.isMb" class="search-panel">
      <div class="overlay">
        <div class="title-small">為客人打造心目中的</div>
        <div class="title">安樂窩</div>
        <div class="content">搜尋項目</div>

        <div class="row option-category-wrapper">
          <div class="col-12" style="text-align: center">
            <div class="custom-btn" v-on:click="resetData">重置</div>
            <div
              class="custom-btn"
              style="margin-left: 100px"
              v-on:click="search"
            >
              搜尋
            </div>
          </div>
        </div>

        <div class="option-wrapper">
          <div class="container-fluid">
            <div class="row option-category-wrapper">
              <div class="col-md-2 col-lg-1" style="text-align: center">
                風格
              </div>
              <div class="col-md-10 col-lg-11">
                <div class="container-fluid">
                  <div class="row">
                    <div
                      class="col-md-4 col-lg-3 col-xl-2"
                      v-for="(s, index) in styleDataSource"
                      v-bind:key="index"
                    >
                      <label class="check-box-container mb-menu-checkbox">
                        {{ s.name }}
                        <input
                          type="checkbox"
                          v-bind:value="s.value"
                          v-model="selectedStyle"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row option-category-wrapper">
              <div class="col-md-2 col-lg-1" style="text-align: center">
                間格
              </div>
              <div class="col-md-10 col-lg-11">
                <div class="container-fluid">
                  <div class="row">
                    <div
                      class="col-md-4 col-lg-3 col-xl-2"
                      v-for="(p, index) in partitionDataSource"
                      v-bind:key="index"
                    >
                      <label class="check-box-container mb-menu-checkbox">
                        {{ p.name }}
                        <input
                          type="checkbox"
                          v-bind:value="p.value"
                          v-model="selectedPartition"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row option-category-wrapper">
              <div class="col-md-2 col-lg-1" style="text-align: center">
                面積
              </div>
              <div class="col-md-10 col-lg-11">
                <div class="container-fluid">
                  <div class="row">
                    <div
                      class="col-md-4 col-lg-3 col-xl-2"
                      v-for="(a, index) in areaDataSource"
                      v-bind:key="index"
                    >
                      <label class="check-box-container mb-menu-checkbox">
                        {{ a.name }}
                        <input
                          type="checkbox"
                          v-bind:value="a.value"
                          v-model="selectedArea"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row option-category-wrapper">
              <div class="col-md-2 col-lg-1" style="text-align: center">
                類型
              </div>
              <div class="col-md-10 col-lg-11">
                <div class="container-fluid">
                  <div class="row">
                    <div
                      class="col-md-4 col-lg-3 col-xl-2"
                      v-for="(a, index) in typeDataSource"
                      v-bind:key="index"
                    >
                      <label class="check-box-container mb-menu-checkbox">
                        {{ a.name }}
                        <input
                          type="checkbox"
                          v-bind:value="a.value"
                          v-model="selectedType"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row option-category-wrapper">
              <div class="col-md-2 col-lg-1" style="text-align: center">
                樓盤
              </div>
              <div class="col-md-10 col-lg-11">
                <div class="container-fluid">
                  <div class="row">
                    <!-- 2022-06-26 - Remove first 3 projects -->
                    <!--<div class="col-md-4 col-lg-3 col-xl-2" v-for="(p, index) in projectDataSource.slice(0, 3)" v-bind:key="index">
                                            <label class="check-box-container mb-menu-checkbox">
                                                {{p.name}}
                                                <input type="checkbox" v-bind:value="p.value" v-model="selectedProject">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>-->

                    <div
                      class="col-md-4 col-lg-3 col-xl-2"
                      style="cursor: pointer"
                      v-on:click="moreClick"
                    >
                      <img
                        src="../assets/expand.png"
                        style="width: 25px; height: auto; margin: 0 7px 0 3px"
                      />
                      更多
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--<div class="row option-category-wrapper">
                            <div class="col-md-2 col-lg-1" style="text-align: center;">&nbsp;</div>
                            <div class="col-md-10 col-lg-11">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3 col-xl-2" style="cursor: pointer;" v-on:click="moreClick">
                                            <img src="../assets/expand.png" style="width: 25px; height: auto; margin: 0 7px 0 3px;" />
                                            更多
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-fluid"
      style="padding-bottom: 20px"
      v-if="!$globalData.isMb || ($globalData.isMb && !isShowMbSearchPanel)"
      ref="projectSection"
    >
      <div class="row">
        <div
          v-bind:style="getProjectContainerStyle(index)"
          class="project col-12 col-md-6"
          v-for="(project, index) in searchProjects"
          v-bind:key="index"
          v-on:click="
            clickProject(
              project.projectId,
              project.projectName,
              project.projectSize
            )
          "
        >
          <div
            v-bind:style="getProjectBackgroundImage(project.img_path)"
            v-bind:key="index"
            class="project-image-wrapper"
          >
            <div class="project-overlay">
              <div
                style="
                  font-size: 1.75em;
                  text-indent: 5px;
                  letter-spacing: 5px;
                  color: #fff;
                  width: 100%;
                  text-align: center;
                  height: 60px;
                  line-height: 60px;
                  position: absolute;
                  left: 0;
                  top: calc(50% - 30px);
                "
              >
                {{ project.projectName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="position: relative; width: 100%; height: 100px; margin: 10px 0"
      v-if="$globalData.isMb && !isShowMbSearchPanel"
    >
      <img
        class="project-first-page"
        src="../assets/first.png"
        v-on:click="firstPage"
        v-if="currentPage != 1"
      />
      <img
        class="project-previous-page"
        src="../assets/previous.png"
        v-on:click="previousPage"
        v-if="currentPage != 1"
      />
      <div style="margin: 0 auto; width: 50px">
        <input
          type="text"
          class="form-control"
          maxlength="3"
          style="text-align: center"
          v-model="inputPage"
          v-on:keyup.enter="pageEnterPress"
        />
      </div>
      <img
        class="project-next-page"
        src="../assets/next.png"
        v-on:click="nextPage"
        v-if="currentPage != totalPage"
      />
      <img
        class="project-last-page"
        src="../assets/last.png"
        v-on:click="lastPage"
        v-if="currentPage != totalPage"
      />
    </div>

    <div
      style="position: relative; width: 100%; height: 100px; margin: 10px 0"
      v-if="!$globalData.isMb"
    >
      <img
        class="project-first-page"
        src="../assets/first.png"
        v-on:click="firstPage"
        v-if="currentPage != 1"
      />
      <img
        class="project-previous-page"
        src="../assets/previous.png"
        v-on:click="previousPage"
        v-if="currentPage != 1"
      />

      <div
        style="margin: 0 auto; padding-top: 10px; text-align: center"
        class="page-number-item-wrapper"
        v-if="totalPage <= 10"
      >
        <span v-for="index in totalPage" :key="index" class="page-number-item">
          <span
            v-if="currentPage == index"
            style="color: #333; font-weight: bold"
            v-on:click="goToPage(index)"
            >{{ index }}</span
          >
          <span v-if="currentPage != index" v-on:click="goToPage(index)">{{
            index
          }}</span>
        </span>
        <!--<input type="text" class="form-control" maxlength="3" style="text-align:center;" v-model="inputPage" v-on:keyup.enter="pageEnterPress" />-->
      </div>

      <div
        style="margin: 0 auto; padding-top: 10px; text-align: center"
        class="page-number-item-wrapper"
        v-if="totalPage > 10"
      >
        <div v-if="currentPage - 6 <= 0">
          <span v-for="index in 10" :key="index" class="page-number-item">
            <span
              v-if="currentPage == index"
              style="color: #333; font-weight: bold"
              v-on:click="goToPage(index)"
              >{{ index }}</span
            >
            <span v-if="currentPage != index" v-on:click="goToPage(index)">{{
              index
            }}</span>
          </span>
        </div>

        <div v-if="currentPage - 6 > 0 && currentPage + 4 < totalPage">
          <span v-for="index in 10" :key="index" class="page-number-item">
            <span
              v-if="currentPage == index + (currentPage - 6)"
              style="color: #333; font-weight: bold"
              v-on:click="goToPage(index + (currentPage - 6))"
              >{{ index + (currentPage - 6) }}</span
            >
            <span
              v-if="currentPage != index + (currentPage - 6)"
              v-on:click="goToPage(index + (currentPage - 6))"
              >{{ index + (currentPage - 6) }}</span
            >
          </span>
        </div>

        <div v-if="currentPage + 4 >= totalPage">
          <span v-for="index in 10" :key="index" class="page-number-item">
            <span
              v-if="currentPage == totalPage - 10 + index"
              style="color: #333; font-weight: bold"
              v-on:click="goToPage(totalPage - 10 + index)"
              >{{ totalPage - 10 + index }}</span
            >
            <span
              v-if="currentPage != totalPage - 10 + index"
              v-on:click="goToPage(totalPage - 10 + index)"
              >{{ totalPage - 10 + index }}</span
            >
          </span>
        </div>

        <!--<input type="text" class="form-control" maxlength="3" style="text-align:center;" v-model="inputPage" v-on:keyup.enter="pageEnterPress" />-->
      </div>

      <img
        class="project-next-page"
        src="../assets/next.png"
        v-on:click="nextPage"
        v-if="currentPage != totalPage"
      />
      <img
        class="project-last-page"
        src="../assets/last.png"
        v-on:click="lastPage"
        v-if="currentPage != totalPage"
      />
    </div>

    <div
      class="popup-overlay"
      v-bind:style="heightPx"
      v-if="clickedProject != null"
      v-on:click="slideShowCloseClick"
    >
      <div class="window" @click.stop="">
        <ProjectSlideShow
          v-bind:project-id="clickedProject"
          v-bind:project-name="clickedProjectName"
          v-bind:project-size="clickedProjectSize"
          v-bind:selected-partition="selectedPartition"
          v-on:closeClick="slideShowCloseClick"
        />
      </div>
    </div>

    <div
      class="popup-overlay"
      v-bind:style="heightPx"
      v-if="!$globalData.isMb && showProjectPanel"
      v-on:click="moreProjectCloseButtonClick"
    >
      <div class="all-project-window" @click.stop="">
        <div
          style="
            position: sticky;
            width: 100%;
            height: 65px;
            background-color: #fff;
            top: 0;
            z-index: 1000002;
          "
        >
          <div
            style="
              position: absolute;
              top: 0;
              right: 0;
              width: 65px;
              height: 65px;
              padding: 20px;
            "
          >
            <img
              style="width: 100%; height: 100%; cursor: pointer"
              src="../assets/close.png"
              v-on:click="moreProjectCloseButtonClick"
            />
          </div>
        </div>
        <div
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 80px;
            height: 100%;
            padding-top: 65px;
            text-align: center;
          "
        >
          樓盤
        </div>

        <div class="container-fluid" style="padding: 0 20px 0 100px">
          <div class="row">
            <div
              class="col-md-6 col-lg-4"
              v-for="(p, index) in projectDataSource"
              v-bind:key="index"
            >
              <label class="green-check-box-container">
                {{ p.name }}
                <input
                  type="checkbox"
                  v-bind:value="p.value"
                  v-model="selectedProject"
                />
                <span class="green-checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectSlideShow from "./ProjectSlideShow";
import axios from "axios";
import {
  GET_STYLE_API_PATH,
  GET_PARTITION_API_PATH,
  GET_SIZE_API_PATH,
  GET_TYPE_API_PATH,
  GET_PROJECT_API_PATH,
  SEARCH_PROJECT_API_PATH,
  SEARCH_PROJECT_PAGE_NUMBER_API_PATH,
} from "../constants";

export default {
  name: "Project",
  components: {
    ProjectSlideShow,
  },
  data() {
    return {
      isShowMbSearchPanel: false,
      mbSearchPanelExpandStyle: false,
      mbSearchPanelExpandPartition: false,
      mbSearchPanelExpandArea: false,
      mbSearchPanelExpandType: false,
      mbSearchPanelExpandProject: false,
      showProjectPanel: false,
      styleDataSource: [{ name: "時尚", value: "fashion" }],
      partitionDataSource: [{ name: "待定", value: "**" }],
      areaDataSource: [{ name: "300 ft 以下", value: "-300" }],
      typeDataSource: [],
      projectDataSource: [{ name: "待定", value: "1**" }],
      projectListDataSource: [],
      projectDetailDataSource: [],
      selectedStyle: [],
      selectedPartition: [],
      selectedArea: [],
      selectedType: [],
      selectedProject: [],
      searchProjects: [],
      clickedProject: null,
      clickedProjectName: null,
      clickedProjectSize: null,
      inputPage: 1,
      currentPage: 1,
      totalPage: 1,
    };
  },
  created: function () {
    let that = this;
    //this.searchProjects = Projects;

    axios.post(GET_STYLE_API_PATH).then(function (res) {
      that.styleDataSource = res.data;
    });

    axios.post(GET_PARTITION_API_PATH).then(function (res) {
      that.partitionDataSource = res.data;
    });

    axios.post(GET_SIZE_API_PATH).then(function (res) {
      that.areaDataSource = res.data;
    });

    axios.post(GET_PROJECT_API_PATH).then(function (res) {
      that.projectDataSource = res.data;
    });

    axios.post(GET_TYPE_API_PATH).then(function (res) {
      that.typeDataSource = res.data;
    });

    this.search();
  },
  methods: {
    moreProjectCloseButtonClick: function () {
      this.showProjectPanel = false;
    },
    pageEnterPress: function () {
      let ip = parseInt(this.inputPage);

      if (ip < 1) {
        this.inputPage = (1).toString();
        this.currentPage = (1).toString();
      } else if (ip > this.totalPage) {
        this.inputPage = this.totalPage.toString();
        this.currentPage = this.totalPage;
      } else this.currentPage = ip;

      this.loadProject();

      let element = this.$refs["projectSection"];
      let offsetTop = element.offsetTop;
      window.scrollTo(0, offsetTop);
    },
    clickProject: function (projectId, projectName, projectSize) {
      this.clickedProject = projectId;
      this.clickedProjectName = projectName;
      this.clickedProjectSize = projectSize;
    },
    toggleStyle: function () {
      this.mbSearchPanelExpandStyle = !this.mbSearchPanelExpandStyle;
    },
    togglePartition: function () {
      this.mbSearchPanelExpandPartition = !this.mbSearchPanelExpandPartition;
    },
    toggleArea: function () {
      this.mbSearchPanelExpandArea = !this.mbSearchPanelExpandArea;
    },
    toggleType: function () {
      this.mbSearchPanelExpandType = !this.mbSearchPanelExpandType;
    },
    toggleProject: function () {
      this.mbSearchPanelExpandProject = !this.mbSearchPanelExpandProject;
    },
    moreClick: function () {
      this.showProjectPanel = true;
    },
    resetData: function () {
      this.selectedStyle = [];
      this.selectedPartition = [];
      this.selectedArea = [];
      this.selectedType = [];
      this.selectedProject = [];
    },
    showMbSearchPanel: function () {
      this.isShowMbSearchPanel = true;
    },
    search: function () {
      let that = this;
      this.isShowMbSearchPanel = false;
      this.inputPage = 1;
      this.currentPage = 1;

      let postData = {
        selectedStyle: this.selectedStyle,
        selectedPartition: this.selectedPartition,
        selectedArea: this.selectedArea,
        selectedProject: this.selectedProject,
        selectedType: this.selectedType,
      };

      axios
        .post(SEARCH_PROJECT_PAGE_NUMBER_API_PATH, postData)
        .then(function (res) {
          that.totalPage = res.data.totalPageCount;
          that.loadProject();
        })
        .catch(function (ex) {
          that.totalPage = 1;
          that.searchProjects = [];
        });
    },
    loadProject: function () {
      let that = this;

      let postData = {
        page: this.currentPage,
        selectedStyle: this.selectedStyle,
        selectedPartition: this.selectedPartition,
        selectedArea: this.selectedArea,
        selectedProject: this.selectedProject,
        selectedType: this.selectedType,
      };

      axios
        .post(SEARCH_PROJECT_API_PATH, postData)
        .then(function (res) {
          that.searchProjects = res.data;
        })
        .catch(function (ex) {
          that.searchProjects = [];
        });
    },
    getProjectBackgroundImage: function (path) {
      let style = 'background-image: url("/' + path + '");';
      return style;
    },
    getProjectContainerStyle: function (index) {
      if (this.$globalData.isMb) return "padding: 10px 10px 0 10px;";

      if (index % 2 == 0) return "padding: 10px 0 0 10px;";
      else return "padding: 10px 10px 0 10px;";
    },
    slideShowCloseClick: function () {
      this.clickedProject = null;
      this.clickedProjectName = null;
      this.clickedProjectSize = null;
    },
    goToPage: function (page) {
      this.currentPage = page;
      this.inputPage = page.toString();
      this.loadProject();

      let element = this.$refs["projectSection"];
      let offsetTop = element.offsetTop;
      window.scrollTo(0, offsetTop);
    },
    firstPage: function () {
      if (this.currentPage === 1) return;

      this.currentPage = 1;
      this.inputPage = this.currentPage.toString();
      this.loadProject();

      let element = this.$refs["projectSection"];
      let offsetTop = element.offsetTop;
      window.scrollTo(0, offsetTop);
    },
    previousPage: function () {
      if (this.currentPage === 1) return;

      this.currentPage -= 1;
      this.inputPage = this.currentPage.toString();
      this.loadProject();

      let element = this.$refs["projectSection"];
      let offsetTop = element.offsetTop;
      window.scrollTo(0, offsetTop);
    },
    nextPage: function () {
      if (this.totalPage === this.currentPage) return;

      this.currentPage += 1;
      this.inputPage = this.currentPage.toString();
      this.loadProject();

      let element = this.$refs["projectSection"];
      let offsetTop = element.offsetTop;
      window.scrollTo(0, offsetTop);
    },
    lastPage: function () {
      if (this.totalPage === this.currentPage) return;

      this.currentPage = this.totalPage;
      this.inputPage = this.currentPage.toString();
      this.loadProject();

      let element = this.$refs["projectSection"];
      let offsetTop = element.offsetTop;
      window.scrollTo(0, offsetTop);
    },
  },
  computed: {
    // a computed getter
    heightPx: function () {
      let style = "min-height: " + this.$globalData.windowHeight + "px;";
      return style;
    },
  },
};
</script>

<style>
</style>